@import "../node_modules/bootstrap/scss/bootstrap";

@media (min-width: 768px){
    .pt-md-11 {
        padding-top: 6rem!important;
    }
}

.text-brand {
    font-weight: lighter !important;
    font-size: 1.3rem !important;
}

.float-nav{
    font-weight: lighter !important;
    font-size: 1.3rem !important;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100vw;
    background-color: #000;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(0 0 0 / 30%) 30%, rgba(0, 0, 0, 0.7) 100%);
}

.brand-topic{
    height: 70vh;
}

.bg-image{
    background: url(/assets/node-bg.png);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -100;
    right: 0;
}

.banner-container {
    background-color: #00000063;
    width: 100%;
    height: 100%;
}

.bg-banner{
    background: url(/assets/banner.png);
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: cover;
    background-attachment: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -100;
    right: 0;
}

.video-container {
    height: 100vh;
    width: 100vw;
    position: relative;
}

.bg-video{
    background: url(/assets/nodes.png);
    width: 100%;
    height: 100%;
    position: fixed;
    object-fit: cover;
    z-index: -2;
}

.bg-shield {
    background-color: rgb(0 0 0 / 40%);
    position: fixed;
    min-width: 100vw;
    min-height: 100vh;
    z-index: -1;
    right: 0;
}

.video-caption{
    z-index: 1;
    position: relative;
    text-align: center;
    padding: 15vw; 
    padding-top: calc(50vh - 4rem);
}

.banner-content {
    background-color: #00000063;
    padding-top: calc(50vh - 5rem);
    width: 100%;
    height: 100%;
}

.hero{
    position: relative;
    width: 100vw;
    height: 100vh;
}

.hero > div {
    width: 100vw;
    position: absolute;
    top: 50%;
    margin-top: -5rem;
}

.hero-info{
    background-color: #f2f2f2;
}
